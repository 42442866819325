<template>
  <v-form ref="form">
    <v-toolbar width="100%" class="mx-0" color="secondary " dark>
      <v-card-text class="text-h5">Create New Room</v-card-text>
    </v-toolbar>

    <v-card class="pa-4">
      <v-row>
        <v-col>
          <v-text-field
            v-model="title"
            label="Title"
            :rules="$requiredRules"
          ></v-text-field>
          <v-combobox
            v-model="selected_categories"
            label="Category"
            :items="categories"
            item-text="title"
            item-value="title"
            chips
            multiple
            :return-object="false"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field label="Section" v-model="section"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="shift"
            :items="[
              {
                text: 'Morning',
                value: 'morning',
              },
              {
                text: 'Day',
                value: 'day',
              },
            ]"
            label="Shift"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field label="Session" v-model="session"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            multiple
            v-model="subjects"
            :items="subject_choices"
            item-value="title"
            cache-items
            label="Add Subjects"
            chips
            :search-input.sync="search"
            item-text="title"
            :loading="loading_subjects"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn
          v-if="from_complete"
          color="blue darken-1"
          text
          @click="redirect_to_profile"
        >
          Skip
        </v-btn> -->
        <v-btn outlined color="red lighten-2" text @click="closeDialog">
          Close
        </v-btn>
        <v-btn outlined :loading="loading" color="secondary" @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import api from "@/services/api";
function initialState() {
  return {
    title: "",
    form: "",
    subject_choices: [],
    selected_categories: [],
    is_administration_room: "",
    search: "",
    subjects: [],
  };
}
export default {
  name: "NewClass",

  props: ["from_complete"],
  data: function () {
    return {
      form: "",
      // id: Math.floor(Math.random() * 1000),
      e1: 1,
      title: "",
      categories: [],
      search: "",
      session: "",
      shift: "",
      section: "",
      selected_categories: [],
      subject_choices: [],
      subjects: [],
      loading_subjects: false,
      loading: false,
    };
  },
  emits: ["closeDialog"],
  // props: ["selectedDate"],
  mounted() {
    api.get("categories/").then((res) => (this.categories = res.data));
    this.loadSubjects();
  },
  watch: {
    search(val) {
      if (val) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(() => {
          this.loadSubjects();
        }, 800);
      }
    },
  },
  methods: {
    async loadSubjects() {
      this.loading_subjects = true;
      const res = await api.get("categories/subjects/", {
        params: {
          ...(this.search ? { search: this.search } : {}),
        },
      });
      this.subject_choices = res.data.results;
      this.loading_subjects = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("classes/add", {
            name: this.title,
            categories: this.selected_categories.map((e) => ({ title: e })),
            subjects: this.subjects.map((e) => ({ title: e })),
            session: this.session,
            shift: this.shift,
            section: this.section,
          })
          .then(() => {
            Object.assign(this.$data, initialState());
            this.$emit("closeDialog");
          })
          .catch(() => {
            alert("An error Occurred");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    to_next_step() {
      this.e1 += 1;
    },
    redirect_to_profile() {
      this.$router.push("/profile");
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
  computed: {},
};
</script>
<style>
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form"},[_c('v-toolbar',{staticClass:"mx-0",attrs:{"width":"100%","color":"secondary ","dark":""}},[_c('v-card-text',{staticClass:"text-h5"},[_vm._v("Create New Room")])],1),_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Title","rules":_vm.$requiredRules},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-combobox',{attrs:{"label":"Category","items":_vm.categories,"item-text":"title","item-value":"title","chips":"","multiple":"","return-object":false},model:{value:(_vm.selected_categories),callback:function ($$v) {_vm.selected_categories=$$v},expression:"selected_categories"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Section"},model:{value:(_vm.section),callback:function ($$v) {_vm.section=$$v},expression:"section"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":[
            {
              text: 'Morning',
              value: 'morning',
            },
            {
              text: 'Day',
              value: 'day',
            },
          ],"label":"Shift"},model:{value:(_vm.shift),callback:function ($$v) {_vm.shift=$$v},expression:"shift"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Session"},model:{value:(_vm.session),callback:function ($$v) {_vm.session=$$v},expression:"session"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"multiple":"","items":_vm.subject_choices,"item-value":"title","cache-items":"","label":"Add Subjects","chips":"","search-input":_vm.search,"item-text":"title","loading":_vm.loading_subjects},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.subjects),callback:function ($$v) {_vm.subjects=$$v},expression:"subjects"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"red lighten-2","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"outlined":"","loading":_vm.loading,"color":"secondary"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }